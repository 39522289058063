import * as yup from 'yup';

export function getBulkUploadPlanDocValidationSchema(): yup.AnyObjectSchema {
  return yup.object().shape({
    documentKeys: yup
      .array()
      .of(yup.string())
      .required()
      .test(
        'unique-document-keys',
        'Document keys must be unique',
        value => new Set(value).size === value.length
      ),
    effectiveDate: yup.string().required()
  });
}
