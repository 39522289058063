import DatePickerForm from '@/components/date-picker/DatePickerForm';
import LinearLoading from '@/components/linear-loading';
import SimpleDropdown from '@/components/simple-dropdown';
import SimpleUpload from '@/components/simple-upload';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { UploadDocumentData } from '@/models/DocumentDTO.model';
import { PlanService } from '@/services/Plan.service';
import { getUploadPlanDocValidationSchema } from '@/utils/validations/UploadPlanDocValidationSchema.schema';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Theme,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import clsx from 'clsx';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import mime from 'mime';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';

import { GroupsWithDates } from './PlanDocumentsGroup.component';

const useStyles = makeStyles((theme: Theme) => ({
  datePicker: {
    width: '43%'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  docName: {
    color: theme.palette.text.secondary
  },
  errorMsg: {
    color: theme.palette.error.main
  },
  selectArea: {
    alignItems: 'center',
    border: `1px solid ${grey[300]}`,
    borderRadius: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    justifyContent: 'center',
    padding: theme.spacing(5)
  },
  selectAreaButtonHover: {
    backgroundColor: theme.palette.primary.dark
  },
  selectAreaHover: {
    borderColor: theme.palette.primary.dark,
    cursor: 'pointer'
  }
}));

interface NewGroupConfig {
  categoryId: number;
  entityType: string;
  entityId: number;
  name: string;
}

interface UploadPlanDialogProps {
  documentGroups?: GroupsWithDates[];
  documentKey?: string;
  planId: number;
  groupingId?: number;
  categoryId: number;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  documentName?: string;
  newGroupConfig?: NewGroupConfig;
}

export type DocumentDefinitionConfig = {
  entity: string;
  documentKey: string;
  config: {
    dev: any;
    extensions: string[];
  } & string[];
  createdAt: any;
  updatedAt: any;
};

const UploadPlanDialog = (props: UploadPlanDialogProps): JSX.Element => {
  const {
    documentGroups,
    documentKey,
    documentName,
    planId,
    groupingId,
    categoryId,
    setOpen,
    newGroupConfig
  } = props;

  const queryClient = useQueryClient();
  const { closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isHovering, setIsHovering] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const [selectedDocumentKey, setSelectedDocumentKey] = useState(documentKey);
  const definitionQuery = useQuery(
    ['PlanService.getDocumentDefinition', selectedDocumentKey],
    async () =>
      selectedDocumentKey
        ? PlanService.getDocumentDefinition('plan', selectedDocumentKey)
        : '',
    {
      enabled: !!selectedDocumentKey
    }
  );

  const { data: categories } = useQuery<string[]>(
    ['PlanService.getDocumentKeysByCategory', newGroupConfig?.categoryId],
    () =>
      PlanService.getDocumentKeysByCategory(newGroupConfig?.categoryId || ''),
    {
      enabled: !!newGroupConfig?.categoryId && !documentKey,
      staleTime: Infinity
    }
  );

  let effectiveDate: string;

  const deleteDuplicatedInvoiceMutation = useMutation(
    ['PlanService.deleteUploadedDoc'],
    (data: { groupId: number; docId: number }) =>
      PlanService.deleteUploadedDoc(planId, data.docId, data.groupId),
    {
      onError: () =>
        showSnackbar({
          message: `Replacing previous invoice failed!`,
          severity: 'error'
        }),
      onSuccess: async () => {
        showSnackbar({
          message: `Success! Invoice replaced`,
          severity: 'success'
        });

        await queryClient.invalidateQueries([
          'PlanService.getDocumentCategoryGroupings',
          props.planId,
          props.categoryId
        ]);
      }
    }
  );

  const uploadPlanDocument = useMutation(
    ['PlanService.uploadPlanDocument'],
    (data: UploadDocumentData) => {
      return PlanService.uploadPlanDocument(
        data.planId,
        data.documentData,
        effectiveDate,
        groupingId
      );
    },
    {
      onError: () => {
        if (setOpen) {
          setOpen(false);
        }
        showSnackbar({
          message: `Failed!`,
          severity: 'error'
        });
      },
      onSuccess: async data => {
        if (
          documentGroups &&
          [
            'Sponsor Notices/Q1 Invoice',
            'Sponsor Notices/Q2 Invoice',
            'Sponsor Notices/Q3 Invoice',
            'Sponsor Notices/Q4 Invoice'
          ].includes(data.documentKey)
        ) {
          const [matchingGroup] =
            documentGroups?.filter(group =>
              isEqual(groupingId, group.groupingId)
            ) ?? [];

          const [matchingDoc] =
            matchingGroup?.documents?.filter(
              oldDocument =>
                isEqual(oldDocument.documentKey, data.documentKey) &&
                isEqual(
                  dayjs(oldDocument.effectiveDate).year(),
                  dayjs(effectiveDate).year()
                )
            ) ?? [];

          if (groupingId && matchingDoc?.uploadHistoryId) {
            deleteDuplicatedInvoiceMutation.mutate({
              docId: matchingDoc.uploadHistoryId,
              groupId: groupingId
            });
          }
        } else if (!newGroupConfig) {
          showSnackbar({
            message: 'Success! Document uploaded',
            severity: 'success'
          });
        }
        await queryClient.invalidateQueries([
          'PlanService.getAllPlanDocuments',
          planId
        ]);
        await queryClient.invalidateQueries([
          'PlanService.getDocumentCategoryGroupings',
          planId,
          categoryId
        ]);
        await queryClient.invalidateQueries([
          'PlanService.getPlanDocUploadHist',
          planId,
          data.documentKey
        ]);
        queryClient.invalidateQueries([
          'PlanService.getDocumentCategoryLegacy',
          planId,
          categoryId
        ]);
      }
    }
  );

  const createDocumentGroupingWithDetails = useMutation(
    ['PlanService.createDocumentGroupingWithDetails'],
    (data: any) => {
      return PlanService.createDocumentGroupingWithDetails(
        newGroupConfig?.entityId as number,
        data
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: `Failed!`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          'PlanService.getDocumentCategoryGroupings',
          newGroupConfig?.entityId,
          newGroupConfig?.categoryId
        ]);
        showSnackbar({
          message: `Success! New group created`,
          severity: 'success'
        });
      }
    }
  );

  const onSubmit = async (values: any) => {
    const documentKeyToUse = documentKey || values.documentKey;
    if (values.document && documentKeyToUse) {
      effectiveDate = values.effectiveDate;
      const formData = new FormData();
      formData.append('file', values.document, values.documentName);
      formData.append('documentKey', documentKeyToUse);
      formData.append('effectiveDate', values.effectiveDate);
      const docInfo: UploadDocumentData = {
        documentData: formData,
        effectiveDate,
        groupingId,
        planId
      };
      const uploadedDocument = await uploadPlanDocument.mutateAsync(docInfo);
      closeDialog();
      if (setOpen) {
        setOpen(false);
      }
      if (newGroupConfig) {
        await createDocumentGroupingWithDetails.mutateAsync({
          ...newGroupConfig,
          details: {
            effectiveDate: values.effectiveDate,
            uploadHistoryId: uploadedDocument.id
          }
        });
      }

      closeDialog();
    }
  };

  let fileExtensions = {};

  if (definitionQuery.isSuccess && definitionQuery.data != '') {
    const documentDefinition = definitionQuery.data as DocumentDefinitionConfig;

    const hasExtension = !!documentDefinition.config?.extensions;
    const reduceDocumentConfigToFileExt = (a: any, extension: string) => ({
      ...a,
      [`${mime.getType(extension.replaceAll('.', ''))}`]: [extension]
    });
    fileExtensions = hasExtension
      ? documentDefinition.config?.extensions?.reduce(
          reduceDocumentConfigToFileExt,
          {}
        )
      : documentDefinition.config?.reduce(reduceDocumentConfigToFileExt, {});
  }

  const disableSelectFile = !Object.keys(fileExtensions).length;

  return (
    <>
      <Formik
        initialValues={{
          document: null,
          documentKey: '',
          documentName: '',
          effectiveDate: dayjs(new Date()).format('MM/DD/YYYY')
        }}
        onSubmit={onSubmit}
        validationSchema={getUploadPlanDocValidationSchema(
          !documentKey,
          props.documentGroups
        )}>
        {({
          setFieldValue,
          values,
          errors,
          isValid,
          isSubmitting,
          setFieldError
        }) => {
          return (
            <Form data-testid='upload-plan-dialog-form'>
              <DialogTitle>
                {documentKey ? `Upload ${documentName}` : 'Create New Group'}
              </DialogTitle>
              {definitionQuery.isFetching && <LinearLoading />}
              <DialogContent className={classes.dialogContent}>
                {!documentKey && (
                  <SimpleDropdown
                    fieldId='documentKey'
                    fieldName='Document Key'
                    fieldValues={categories || []}
                    onChange={e => {
                      setFieldValue('documentKey', e.target.value);
                      setSelectedDocumentKey(e.target.value);
                    }}
                  />
                )}
                <SimpleUpload
                  accept={fileExtensions}
                  disabled={disableSelectFile}
                  onSelect={files => {
                    if (Array.isArray(files)) {
                      setFieldValue('document', files[0]);
                      setFieldValue('documentName', files[0].name, true);
                    }
                  }}
                  selectRawFiles
                  showFileTypes>
                  <Box
                    className={clsx(
                      isHovering &&
                        !disableSelectFile &&
                        classes.selectAreaHover,
                      classes.selectArea
                    )}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                    <Button
                      className={clsx(
                        isHovering && classes.selectAreaButtonHover
                      )}
                      disabled={disableSelectFile}
                      ref={buttonRef}
                      startIcon={<FileUploadOutlinedIcon />}
                      variant='contained'>
                      Select File
                    </Button>
                    <Typography
                      className={clsx(
                        !values.documentName && classes.docName,
                        errors.documentName && classes.errorMsg
                      )}
                      variant='caption'>
                      {errors.documentName ||
                        values.documentName ||
                        'No file chosen'}
                    </Typography>
                  </Box>
                </SimpleUpload>
                <Divider color={grey[200]} />
                <DatePickerForm
                  className={classes.datePicker}
                  data-testid='upload-doc-effective-date'
                  format='MM/DD/YYYY'
                  handleError={error => {
                    if (error === 'invalidDate') {
                      setFieldError('effectiveDate', '');
                    }
                  }}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  label='Effective date'
                  name='effectiveDate'
                  onChange={() => {}}
                  value={values.effectiveDate}
                  variant='outlined'
                />
              </DialogContent>
              <DialogActions>
                <Button
                  data-testid='upload-plan-dialog-cancel'
                  disabled={uploadPlanDocument.isLoading || isSubmitting}
                  onClick={() => {
                    closeDialog();
                  }}>
                  Cancel
                </Button>
                <Button
                  data-testid='upload-plan-dialog-submit'
                  disabled={
                    !isValid ||
                    !values.document ||
                    !(documentKey || values.documentKey) ||
                    uploadPlanDocument.isLoading ||
                    definitionQuery.isFetching ||
                    isSubmitting
                  }
                  type='submit'>
                  Upload
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default UploadPlanDialog;
