import AccessControl from '@/components/access-control/AccessControl.component';
import { CellComponentProps } from '@/components/collapsible-table';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { AdvisorByFirmDto } from '@/models';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import AdvisorService from '@/services/Advisor.service';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  TableCell,
  Theme,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useCopyToClipboard, useToggle } from 'react-use';

const useStyles = makeStyles((theme: Theme) => ({
  badgeContainerPending: {
    backgroundColor: '#EEEEEE'
  },
  badgeContainerRegistered: {
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), rgb(76, 175, 80)'
  },
  badgeTextPending: {
    color: '#757575'
  },
  badgeTextRegistered: {
    color: '#3B873E'
  },
  tableCell: {
    fontSize: theme.spacing(2)
  }
}));

const AdvisorIdCell: React.FC<{ row: AdvisorByFirmDto }> = ({ row }) => {
  return (
    <Link component={RouterLink} to={`/advisor/${row.advisorId}`}>
      {row.advisorId}
    </Link>
  );
};

const RegistrationStatusCell: React.FC<{ row: AdvisorByFirmDto }> = ({
  row
}) => {
  const classes = useStyles();

  if (row.activationDate) {
    return (
      <Box
        borderRadius='1.75rem'
        className={classes.badgeContainerRegistered}
        display='inline-block'
        px='0.75rem'
        py='0.375rem'>
        <Typography className={classes.badgeTextRegistered} variant='body2'>
          Registered
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      borderRadius='1.75rem'
      className={classes.badgeContainerPending}
      display='inline-block'
      px='0.75rem'
      py='0.375rem'>
      <Typography className={classes.badgeTextPending} variant='body2'>
        Pending
      </Typography>
    </Box>
  );
};

const ActionsCell: React.FC<{
  row: AdvisorByFirmDto & { firmCompanyName?: string };
}> = ({ row }) => {
  const [isLinkCopyRequested, setIsLinkCopyRequested] = useState(false);
  const [, copyLinkToClipboard] = useCopyToClipboard();
  const { showSnackbar } = useSnackbar();
  const [removeDialogOpen, toggleRemoveDialogOpen] = useToggle(false);
  const queryClient = useQueryClient();

  const advisorUserInvites = useQuery(
    ['advisorUserInvites', row.advisorId],
    () => AdvisorService.getAdvisorUserInvites(row.advisorId, row.email, true),
    {
      enabled: isLinkCopyRequested,
      staleTime: Infinity
    }
  );

  const deleteAdvisor = useMutation(
    ['AdvisorService.deleteAdvisor', row.advisorId],
    () => {
      return AdvisorService.deleteAdvisor(row.advisorId);
    },
    {
      onError: () => {
        toggleRemoveDialogOpen();
        showSnackbar({
          message: 'Failed to remove advisor',
          severity: 'error'
        });
      },
      onSuccess: () => {
        toggleRemoveDialogOpen();
        showSnackbar({
          message: `Advisor ${row.name} removed`,
          severity: 'success'
        });
        queryClient.invalidateQueries({
          predicate: ({ queryHash }) => /(Advisor|Firm)Service/.test(queryHash)
        });
      }
    }
  );

  const sendAdvisorUserInvite = useMutation(
    ['sendAdvisorUserInvite', row.advisorId, row.email],
    async () => {
      const advisorUserInvite = await AdvisorService.createAdvisorUserInvite(
        row.advisorId,
        row.email as string,
        ['1', '3', '4', '5', '6']
      );
      await advisorUserInvites.refetch();
      await AdvisorService.sendAdvisorUserInvite(
        row.advisorId,
        row.email as string,
        advisorUserInvite.data.attributes.inviteCode
      );
      showSnackbar({
        message: 'Registration link has been sent!',
        severity: 'success'
      });
    }
  );

  useEffect(() => {
    if (isLinkCopyRequested && !!advisorUserInvites.data) {
      const invites = advisorUserInvites.data?.advisorUserInvites?.length
        ? [...advisorUserInvites.data.advisorUserInvites].sort(
            (a, b) =>
              new Date(b.attributes.expiresAt).getTime() -
              new Date(a.attributes.expiresAt).getTime()
          )
        : [];

      const registrationLink =
        invites[0] && advisorUserInvites.data?.registrationUrl
          ? `${advisorUserInvites.data?.registrationUrl}${invites[0].attributes.inviteCode}`
          : '';
      if (registrationLink) {
        copyLinkToClipboard(registrationLink);
        showSnackbar({
          message: 'Registration link has been copied!',
          severity: 'success'
        });
      } else {
        showSnackbar({
          message: 'You should generate the link by clicking on "Send Link"',
          severity: 'error'
        });
      }

      setIsLinkCopyRequested(false);
    }
  }, [
    isLinkCopyRequested,
    advisorUserInvites.data,
    copyLinkToClipboard,
    showSnackbar
  ]);

  return (
    <>
      <Dialog
        data-testid={`remove-advisor-${row.advisorId}-dialog`}
        onClose={() => toggleRemoveDialogOpen()}
        open={removeDialogOpen}>
        <DialogTitle style={{ cursor: 'move' }}>Remove Advisor</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove advisor <b>{row.name}</b> from{' '}
            {row.firmCompanyName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleRemoveDialogOpen}>Don&apos;t remove</Button>
          <Button
            onClick={() => deleteAdvisor.mutateAsync()}
            variant='contained'>
            Remove Advisor
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            disabled={!!row.activationDate || sendAdvisorUserInvite.isLoading}
            onClick={() => sendAdvisorUserInvite.mutate()}
            variant='text'>
            Send Link
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={!!row.activationDate || isLinkCopyRequested}
            onClick={() => setIsLinkCopyRequested(true)}
            variant='text'>
            Copy Link
          </Button>
        </Grid>
        <Grid item>
          <AccessControl
            requiresOneOf={[
              FeatureLevelPermissions.WRITE_ADVISOR_DELETE,
              FeatureLevelPermissions.WORK_IN_PROGRESS
            ]}>
            <Button onClick={toggleRemoveDialogOpen} variant='text'>
              Remove
            </Button>
          </AccessControl>
        </Grid>
      </Grid>
    </>
  );
};

const FirmDetailsAdvisorsTableCell: React.FC<CellComponentProps> = (
  props: CellComponentProps
) => {
  const classes = useStyles();
  const { row, column } = props;

  const CellComponent = useMemo(() => {
    switch (column.field) {
      case 'advisorId':
        return <AdvisorIdCell row={row} />;
      case 'registrationStatus':
        return <RegistrationStatusCell row={row} />;
      case 'createdAt':
        return <>{dayjs(row[column.field]).format('MM/DD/YYYY')}</>;
      case 'actions':
        return <ActionsCell row={row} />;
      default:
        return <>{row[column.field]}</>;
    }
  }, [row, column.field]);

  return (
    <TableCell
      className={clsx(classes.tableCell, column.cellClassName)}
      component='th'
      scope='row'>
      {CellComponent}
    </TableCell>
  );
};

export default memo(FirmDetailsAdvisorsTableCell);
