import DatePickerForm from '@/components/date-picker/DatePickerForm';
import {
  FieldDisplayController,
  FormContainer,
  FormDialog
} from '@/components/form-dialog/FormDialog.component';
import { RadioGroupForm } from '@/components/radio-group-form/RadioGroupForm.component';
import { RadioLabel } from '@/components/radio-group-form/RadioLabel.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { CreateDocumentGroupingWithDetailsPayload } from '@/models/CreateDocumentGroupingWithDetailsPayload.model';
import { PlanDocument } from '@/models/PlanDocumentCategoryGroupDTO.model';
import { PlanService } from '@/services/Plan.service';
import { getUniqueEffectivePerDocumentGroups } from '@/utils/validations/UploadPlanDocValidationSchema.schema';
import { Alert, Box } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { startCase } from 'lodash';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';

import { useGetDocumentGroups } from '../useGetDocumentGroups';

type UpdateDocumentFormValues = {
  newGroupId?: number | 'create';
  effectiveDate?: string;
};

export type UpdateDraftAndGroupDialogProps = {
  action:
    | 'revertDocumentToDraft'
    | 'publishDocument'
    | 'moveDocument'
    | 'publishDraftGroup';
  document?: PlanDocument & { documentName: string };
  open: boolean;
  onClose: () => void;
  sponsorPlanId: number;
  categoryId: number;
  categoryName: string;
  useEffectiveDate: boolean;
  parentDocKey: string;
  groupId: number;
};

export const UpdateDraftAndGroupDialog: React.FC<
  UpdateDraftAndGroupDialogProps
> = props => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const createDocumentGroupingWithDetails = useMutation(
    ['PlanService.createDocumentGroupingWithDetails'],
    (data: CreateDocumentGroupingWithDetailsPayload) => {
      return PlanService.createDocumentGroupingWithDetails(
        props.sponsorPlanId,
        data
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: `Failed!`,
          severity: 'error'
        });
      }
    }
  );

  const invalidateQueries = () => {
    if (props.document) {
      queryClient.invalidateQueries([
        'PlanService.getPlanDocUploadHist',
        props.sponsorPlanId,
        props.document.documentKey
      ]);
    }

    queryClient.invalidateQueries([
      'PlanService.getDocumentCategoryGroupings',
      props.sponsorPlanId,
      props.categoryId
    ]);
    queryClient.invalidateQueries([
      'PlanService.getAllPlanDocuments',
      props.sponsorPlanId
    ]);
    queryClient.invalidateQueries([
      'PlanService.getDocumentCategoryLegacy',
      props.sponsorPlanId,
      props.categoryId
    ]);
  };

  const publishDraftGroupDocuments = useMutation(
    ['SponsorService.publishDraftGroupDocuments', props.document, props.action],
    (data: UpdateDocumentFormValues) => {
      return PlanService.publishDraftGroupDocuments(
        props.sponsorPlanId,
        props.categoryId,
        {
          effectiveDate: data.effectiveDate,
          newGroupId: +data.newGroupId
        }
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: `Failed to ${startCase(props.action)}`,
          severity: 'error'
        });
      },
      onSuccess: (data, variables) => {
        if (!data.successes) {
          throw new Error('All publish draft group documents failed');
        }
        invalidateQueries();
        showSnackbar({
          message: data.errors
            ? `${data.successes} draft documents published successfully and ${data.errors} failed`
            : `Success! Draft group published with effective date ${variables.effectiveDate}`,
          severity: data.errors ? 'warning' : 'success'
        });
      }
    }
  );

  const updateDocument = useMutation(
    ['SponsorService.updateDocument', props.document, props.action],
    ({ newGroupId, effectiveDate }: UpdateDocumentFormValues) => {
      return PlanService.updateDocument({
        id: props.document?.uploadHistoryId,
        isDraft: props.action === 'revertDocumentToDraft' ? true : false,
        ...(isNaN(+newGroupId) || !newGroupId
          ? {}
          : {
              moveGroup: {
                newGroupId: +newGroupId,
                oldGroupId: props.groupId
              }
            }),
        ...(effectiveDate ? { effectiveDate } : {})
      });
    },
    {
      onError: () => {
        showSnackbar({
          message: `Failed to ${startCase(props.action)}`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        invalidateQueries();
        showSnackbar({
          message: `Success! Document ${props.action === 'revertDocumentToDraft' ? 'Reverted to Draft ' : props.action === 'publishDocument' ? 'Published ' : 'Moved '}`,
          severity: 'success'
        });
      }
    }
  );

  const { sortedGroups } = useGetDocumentGroups(
    props.sponsorPlanId,
    props.categoryId,
    props.useEffectiveDate,
    props.parentDocKey
  );

  const schema = useMemo(
    () =>
      yup.object().shape({
        effectiveDate: yup.string().when('newGroupId', {
          is: 'create',
          otherwise: yup.string().nullable(),
          then: getUniqueEffectivePerDocumentGroups(sortedGroups)
        }),
        newGroupId: yup.mixed().required()
      }),
    []
  );
  const documentGroupsOptions = useMemo(
    () =>
      sortedGroups
        .filter(
          group =>
            group.groupingId !== props.groupId &&
            group.groupingId !== -1 &&
            group.groupingId !== -2
        )
        .map(group => ({
          option: group.nameWithEffectiveDate,
          value: group.groupingId
        })),
    [sortedGroups]
  );

  const onSubmit = useCallback(
    async (data: UpdateDocumentFormValues) => {
      const dataToSend: UpdateDocumentFormValues = {
        newGroupId: data.newGroupId
      };
      if (data.newGroupId === 'create') {
        const newGrouping = await createDocumentGroupingWithDetails.mutateAsync(
          {
            categoryId: props.categoryId,
            entityId: props.sponsorPlanId,
            entityType: 'plan',
            name: props.categoryName
          }
        );
        dataToSend.effectiveDate = data.effectiveDate;
        dataToSend.newGroupId = newGrouping.groupId;
      }

      props.action === 'publishDraftGroup'
        ? publishDraftGroupDocuments.mutateAsync(dataToSend)
        : updateDocument.mutateAsync(dataToSend);
    },
    [updateDocument]
  );

  return (
    <FormDialog
      data-testid='update-draft-and-group-dialog'
      fullWidth
      initialValues={
        props.action === 'publishDraftGroup' ? { newGroupId: 'create' } : {}
      }
      maxWidth='sm'
      onClose={props.onClose}
      onSubmit={onSubmit}
      open={props.open}
      title={
        props.action === 'revertDocumentToDraft'
          ? `Revert ${props.document?.documentName} to Draft`
          : props.action === 'publishDraftGroup'
            ? 'Publish draft group?'
            : `${props.action === 'publishDocument' ? 'Publish' : 'Move'} ${props.document?.documentName} Document`
      }
      validationSchema={
        props.action === 'revertDocumentToDraft' ? null : schema
      }>
      {props.action !== 'revertDocumentToDraft' ? (
        <FormContainer>
          <RadioGroupForm
            label={
              props.action === 'publishDraftGroup'
                ? 'Enter an effective date for this group'
                : 'Available groups'
            }
            name='newGroupId'>
            <FieldDisplayController
              showWhen={() => props.action !== 'publishDraftGroup'}>
              <RadioLabel label='Create a new group' value='create' />
            </FieldDisplayController>
            <FieldDisplayController
              showWhen={formValues => formValues.newGroupId === 'create'}>
              <Box my={2}>
                <DatePickerForm
                  data-testid='effective-date-date-picker'
                  format='MM/DD/YYYY'
                  fullWidth={false}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  label='Effective date'
                  name='effectiveDate'
                  onChange={() => {}}
                  variant='outlined'
                />
                <Alert severity='warning' sx={{ mt: 1 }}>
                  Documents in this group will be displayed in client portals.
                </Alert>
              </Box>
            </FieldDisplayController>
            {props.action !== 'publishDraftGroup' &&
              documentGroupsOptions.map(option => (
                <RadioLabel
                  key={option.value}
                  label={option.option}
                  value={option.value}
                />
              ))}
          </RadioGroupForm>
        </FormContainer>
      ) : (
        <FormContainer continueButtonText='CONFIRM'>
          <Alert severity='warning'>
            Documents in this group will <b>NOT</b> be displayed in client
            portals.
          </Alert>
        </FormContainer>
      )}
    </FormDialog>
  );
};
