import {
  LoanDetailsResponseDto,
  LoanExpectedPayment,
  LoanStatus,
  UpdateLoanAttributes,
  UpdateLoanDto
} from '@/models/LoanDTO.model';
import ParticipantService from '@/services/Participant.service';
import { Box, Button, Modal, Stack, Tooltip, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { FC, useState } from 'react';
import { useToggle } from 'react-use';

import { LoanReamortizationDialog } from './LoanReamortizationDialog.component';

type LoanActionsProps = {
  loanDetails: LoanDetailsResponseDto;
  loanExpectedPayments: LoanExpectedPayment[];
  missingParticipantAddressinfo: boolean;
  onSubmit: () => void;
  participantId: string;
  planBlackoutActive: boolean;
  sponsorPlanId: number;
  suspiciousActivity: boolean;
  tradeRequestStatus?: string;
};

export const LoanActions: FC<LoanActionsProps> = (props: LoanActionsProps) => {
  const [open, toggleOpen] = useToggle(false);
  const [openReamortize, toggleOpenReamortize] = useToggle(false);
  const [newStatus, setNewStatus]: [
    LoanStatus | undefined,
    React.Dispatch<React.SetStateAction<LoanStatus | undefined>>
  ] = useState();

  const loanStatusMutation = useMutation(
    [
      'ParticipantService.updateLoan',
      props.participantId,
      props.loanDetails?.data?.id
    ],
    (attr: UpdateLoanAttributes) =>
      ParticipantService.updateLoan(
        +props.participantId,
        +props.loanDetails?.data?.id,
        {
          attributes: { ...attr },
          id: props.loanDetails?.data?.id
        } as UpdateLoanDto
      ),
    {
      onSuccess: () => {
        props.onSubmit();
      }
    }
  );

  return (
    <>
      {!props.loanDetails?.data.attributes.isReamortizing && (
        <Stack alignItems='center' direction='row' spacing={2}>
          {props.loanDetails?.data.attributes.loanStatus ===
            'Fraud Check Pending' && (
            <Tooltip
              disableHoverListener={
                !props.planBlackoutActive &&
                !props.missingParticipantAddressinfo
              }
              title={
                props.planBlackoutActive
                  ? 'Plan is in blackout period.'
                  : props.missingParticipantAddressinfo
                    ? 'Participant is missing address info.'
                    : ''
              }>
              <span>
                <Button
                  color='primary'
                  data-testid='mark-as-approved'
                  disabled={
                    props.planBlackoutActive ||
                    props.suspiciousActivity ||
                    props.missingParticipantAddressinfo
                  }
                  onClick={() => {
                    setNewStatus('Disbursement Pending');
                    toggleOpen();
                  }}
                  variant='outlined'>
                  Approve
                </Button>
              </span>
            </Tooltip>
          )}
          {['Awaiting Participant Acceptance', 'Fraud Check Pending'].includes(
            props.loanDetails?.data.attributes.loanStatus
          ) && (
            <>
              <Button
                color='primary'
                data-testid='mark-as-rejected'
                onClick={() => {
                  setNewStatus('Rejected');
                  toggleOpen();
                }}
                variant='outlined'>
                Reject
              </Button>
              <Button
                color='error'
                data-testid='mark-as-canceled'
                onClick={() => {
                  setNewStatus('Canceled');
                  toggleOpen();
                }}
                variant='outlined'>
                Cancel
              </Button>
            </>
          )}
          {['Past Due', 'Deemed'].includes(
            props.loanDetails?.data.attributes.loanStatus
          ) && (
            <Button
              color='primary'
              data-testid='mark-as-offset'
              onClick={() => {
                setNewStatus('Offset');
                toggleOpen();
              }}
              variant='outlined'>
              Offset
            </Button>
          )}
          {['Live', 'Past Due', 'Deemed'].includes(
            props.loanDetails?.data?.attributes.loanStatus
          ) && (
            <>
              <Button
                color='primary'
                data-testid='mark-as-paid'
                onClick={() => {
                  setNewStatus('Paid in Full');
                  toggleOpen();
                }}
                variant='outlined'>
                Mark as Paid
              </Button>
              <Button
                color='primary'
                data-testid='mark-as-reamortize'
                onClick={toggleOpenReamortize}
                variant='outlined'>
                Reamortize
              </Button>
            </>
          )}
          {props.loanDetails?.data?.attributes.loanStatus === 'Live' && (
            <Button
              color='primary'
              data-testid='mark-as-past-due'
              onClick={() => {
                setNewStatus('Past Due');
                toggleOpen();
              }}
              variant='outlined'>
              Mark as Past Due
            </Button>
          )}
          {props.loanDetails?.data?.attributes.loanStatus === 'Past Due' && (
            <Button
              color='primary'
              data-testid='mark-as-deemed'
              onClick={() => {
                setNewStatus('Deemed');
                toggleOpen();
              }}
              variant='outlined'>
              Deemed
            </Button>
          )}
          {'Disbursement Pending' ===
            props.loanDetails?.data?.attributes.loanStatus &&
            (props.tradeRequestStatus === undefined ||
              ['SUBA_ABORTED', 'SUBA_FAILED'].includes(
                props.tradeRequestStatus
              )) && (
              <Button
                color='error'
                data-testid='mark-as-canceled'
                onClick={() => {
                  setNewStatus('Canceled');
                  toggleOpen();
                }}
                variant='outlined'>
                Cancel
              </Button>
            )}
        </Stack>
      )}
      <Modal onClose={toggleOpen} open={open}>
        <Box
          sx={{
            backgroundColor: '#FAFAFA',
            boxShadow: '24px',
            left: '50%',
            padding: theme => theme.spacing(4),
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}>
          <Typography component='div' variant='h5'>
            Update Loan
          </Typography>
          <Typography
            sx={{
              marginBottom: theme => theme.spacing(4),
              marginTop: theme => theme.spacing(4)
            }}
            variant='body1'>
            Are you sure you want to change the status to {newStatus}?
          </Typography>
          <Button
            disabled={!newStatus}
            onClick={() => {
              if (newStatus) loanStatusMutation.mutate({ status: newStatus });
              toggleOpen();
            }}
            variant='contained'>
            CONFIRM
          </Button>
          <Button onClick={toggleOpen}>CANCEL</Button>
        </Box>
      </Modal>
      <LoanReamortizationDialog
        onClose={toggleOpenReamortize}
        onSubmit={props.onSubmit}
        open={openReamortize}
        originalLoan={props.loanDetails}
        originalPayments={props.loanExpectedPayments}
        sponsorPlanId={props.sponsorPlanId}
      />
    </>
  );
};

LoanActions.displayName = 'LoanActions';
