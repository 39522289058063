import AccessControl from '@/components/access-control/AccessControl.component';
import { Order } from '@/components/collapsible-table';
import { DataTableMenuWithIcons } from '@/components/data-table/DataTable.component';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import PlanDocumentCategoryGroup, {
  PlanDocument
} from '@/models/PlanDocumentCategoryGroupDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import InMemoryFileDownloadService from '@/services/InMemoryFileDownloadService.service';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import HistoryIcon from '@mui/icons-material/History';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import UndoIcon from '@mui/icons-material/Undo';
import {
  Box,
  Button,
  Link,
  ListItemIcon,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@tanstack/react-query';

import React, { useCallback, useMemo, useState } from 'react';

import DeleteDocDialog from './delete_doc/DeleteDocDialog.component';
import DocDetailsDialog from './doc_details/DocDetailsDialog.component';
import {
  UpdateDraftAndGroupDialog,
  UpdateDraftAndGroupDialogProps
} from './update_docs_status/UpdateDraftAndGroupDialog.component';
import DocUploadHistoryDialog from './upload_history/DocUploadHistoryDialog.component';
import UploadPlanDialog from './UploadPlanDialog.component';

type PlanDocumentsTableProps = {
  documents: PlanDocument[];
  documentGroups?: PlanDocumentCategoryGroup[];
  sponsorPlanId: number;
  useEffectiveDate: boolean;
  groupId: number;
  categoryId: number;
  categoryName: string;
  parentDocKey: string;
  isTPA: boolean;
};

const useStyles = makeStyles(() => ({
  buttonIcon: {
    '& .MuiButton-startIcon': {
      margin: 0
    },
    '&:hover': {
      backgroundColor: 'white'
    },
    color: grey[500],
    justifyContent: 'flex-start',
    minWidth: 'auto'
  }
}));

const PlanDocumentsTable: React.FunctionComponent<PlanDocumentsTableProps> = ({
  documents = [],
  documentGroups = [],
  sponsorPlanId,
  useEffectiveDate,
  groupId,
  categoryId,
  categoryName,
  parentDocKey,
  isTPA
}) => {
  const { openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [selectedDocumentAction, setSelectedDocumentAction] = useState<{
    document?: PlanDocument & { documentName: string };
    action: UpdateDraftAndGroupDialogProps['action'];
  } | null>(null);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string | undefined>();

  let hasDocuments: boolean;

  const { mutate: downloadDocument } = useMutation(
    ['PlanService.getDocumentForPlanId', sponsorPlanId],
    (documentId: number) => {
      return PlanService.getDocumentForPlanId(sponsorPlanId, documentId);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Something went wrong with the document download',
          severity: 'error'
        });
      },
      onSuccess: data => {
        InMemoryFileDownloadService.triggerFileDownload(
          data.base64Data,
          data.originalFileName
        );
      }
    }
  );

  const onClickSort = useCallback(
    cellId => {
      return () => {
        const isAsc = orderBy === cellId && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(cellId);
      };
    },
    [orderBy, order]
  );

  const headCells = useMemo(
    () => [
      {
        field: 'documentKey',
        label: 'File',
        sx: {
          borderRight: `1px solid ${grey[300]}`
        }
      },
      {
        field: 'effectiveDate',
        label: useEffectiveDate ? 'Effective Date' : 'Effective Year '
      },
      { field: 'fileName', label: 'File Name' },
      { field: 'createdAt', label: 'Uploaded At' }
    ],
    [useEffectiveDate]
  );

  const sortedDocuments = useMemo(
    () =>
      orderBy
        ? documents.sort((dA, dB) => {
            let a = dA[orderBy] || '';
            let b = dB[orderBy] || '';
            if (orderBy === 'effectiveDate' || orderBy === 'createdAt') {
              a = a?.split('/').reverse().join('');
              b = b?.split('/').reverse().join('');
            }
            if (orderBy === 'documentKey') {
              a = formatters.getDocumentName(a);
              b = formatters.getDocumentName(b);
            }
            return order === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
          })
        : documents,
    [documents, orderBy, order]
  );

  return (
    <>
      <UpdateDraftAndGroupDialog
        action={selectedDocumentAction?.action}
        categoryId={categoryId}
        categoryName={categoryName}
        document={selectedDocumentAction?.document}
        groupId={groupId}
        onClose={() => setSelectedDocumentAction(null)}
        open={!!selectedDocumentAction}
        parentDocKey={parentDocKey}
        sponsorPlanId={sponsorPlanId}
        useEffectiveDate={useEffectiveDate}
      />
      {groupId === -2 && (
        <Stack direction='row' justifyContent='flex-end' m={1} pr={1}>
          <Button
            data-testid='publish-draft-group-button'
            disabled={!documents?.filter(d => !!d.fileName).length}
            onClick={() =>
              setSelectedDocumentAction({ action: 'publishDraftGroup' })
            }
            startIcon={<FolderSpecialIcon />}>
            Publish Group
          </Button>
        </Stack>
      )}
      <TableContainer data-testid='plan-documents-table'>
        <Table aria-label='simple table' sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow
              sx={{ '& .MuiTableCell-head ': { pb: '10px', pt: '10px' } }}>
              {headCells.map(headCell => (
                <TableCell
                  key={headCell.label}
                  sortDirection={orderBy === headCell.label ? order : false}>
                  <TableSortLabel
                    active={orderBy === headCell.field}
                    data-testid={`${headCell.field}-sort-label`}
                    direction={orderBy === headCell.field ? order : 'asc'}
                    onClick={onClickSort(headCell.field)}>
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedDocuments.map((document, index) => {
              hasDocuments = Boolean(document.fileName);
              const effectiveDateValue = !document.effectiveDate
                ? '--'
                : useEffectiveDate
                  ? document.effectiveDate
                  : formatters.formatFromIsoDateCustom(
                      document.effectiveDate,
                      'YYYY'
                    );

              const documentName = formatters.getDocumentName(
                document.documentKey
              );

              return (
                <TableRow
                  data-testid={`documents-table-row-document-${
                    document.uploadHistoryId || documentName
                  }`}
                  key={`${documentName}${index}`}
                  sx={{
                    '& .MuiTableCell-body': { p: '6px 16px' },
                    '&:last-child td, &:last-child th': { borderBottom: 0 }
                  }}>
                  <TableCell
                    component='th'
                    scope='row'
                    sx={{
                      borderRight: `1px solid ${grey[300]}`,
                      height: '40px',
                      maxWidth: '380px',
                      padding: '0px'
                    }}>
                    <Box
                      alignItems='center'
                      display='flex'
                      justifyContent='space-between'>
                      <Box
                        data-testid='document-name-row'
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}>
                        {documentName}
                      </Box>
                      <Box
                        alignItems='center'
                        data-testid='document-action-box'
                        display='flex'
                        sx={{ mr: '-8px' }}>
                        {hasDocuments ? (
                          <DataTableMenuWithIcons
                            data-testid='document-action-menu-button'
                            menuItems={[
                              {
                                component: (
                                  <MenuItem
                                    data-testid='document-upload-history-menu-item'
                                    onClick={() => {
                                      openDialog({
                                        customContent: (
                                          <DocUploadHistoryDialog
                                            categoryId={categoryId}
                                            documentKey={document.documentKey}
                                            documentName={documentName}
                                            groupId={groupId}
                                            planId={sponsorPlanId}
                                            uploadHistoryId={
                                              document.uploadHistoryId
                                            }
                                          />
                                        ),
                                        dialogProps: {
                                          fullWidth: true,
                                          maxWidth: 'md'
                                        }
                                      });
                                    }}>
                                    <ListItemIcon>
                                      <HistoryIcon fontSize='small' />
                                    </ListItemIcon>
                                    View Upload History
                                  </MenuItem>
                                )
                              },
                              {
                                component: (
                                  <AccessControl
                                    requires={[
                                      FeatureLevelPermissions.WRITE_DOCUMENTS_UPLOAD
                                    ]}>
                                    <MenuItem
                                      data-testid='document-upload-menu-item'
                                      onClick={() =>
                                        openDialog({
                                          customContent: (
                                            <UploadPlanDialog
                                              categoryId={categoryId}
                                              documentGroups={documentGroups}
                                              documentKey={document.documentKey}
                                              documentName={documentName}
                                              groupingId={groupId}
                                              planId={sponsorPlanId}
                                              setOpen={setOpen}
                                            />
                                          )
                                        })
                                      }>
                                      <ListItemIcon>
                                        <FileUploadOutlinedIcon />
                                      </ListItemIcon>
                                      Upload New Document
                                    </MenuItem>
                                  </AccessControl>
                                ),
                                isHidden: groupId === -1
                              },
                              {
                                component: (
                                  <AccessControl
                                    requires={[
                                      FeatureLevelPermissions.WRITE_DOCUMENTS_DELETE
                                    ]}>
                                    <MenuItem
                                      data-testid='document-delete-menu-item'
                                      onClick={() => {
                                        openDialog({
                                          customContent: (
                                            <DeleteDocDialog
                                              categoryId={categoryId}
                                              docId={document.uploadHistoryId}
                                              documentKey={document.documentKey}
                                              groupId={groupId}
                                              planId={sponsorPlanId}
                                            />
                                          )
                                        });
                                      }}>
                                      <ListItemIcon>
                                        <DeleteOutlineOutlinedIcon fontSize='small' />
                                      </ListItemIcon>
                                      Delete Document
                                    </MenuItem>
                                  </AccessControl>
                                )
                              },
                              {
                                component: (
                                  <AccessControl
                                    requires={[
                                      FeatureLevelPermissions.WRITE_DOCUMENTS_UPLOAD
                                    ]}>
                                    <MenuItem
                                      data-testid='document-edit-effective-date-menu-item'
                                      onClick={() =>
                                        openDialog({
                                          customContent: (
                                            <DocDetailsDialog
                                              categoryId={categoryId}
                                              effectiveDate={
                                                document.effectiveDate
                                              }
                                              groupId={groupId}
                                              planId={sponsorPlanId}
                                              uploadHistoryId={
                                                document.uploadHistoryId
                                              }
                                            />
                                          ),
                                          dialogProps: {
                                            fullWidth: false
                                          }
                                        })
                                      }>
                                      <ListItemIcon>
                                        <ModeEditOutlineOutlinedIcon fontSize='small' />
                                      </ListItemIcon>
                                      Edit Effective Date
                                    </MenuItem>
                                  </AccessControl>
                                ),
                                isHidden: groupId === -1 || !useEffectiveDate
                              },
                              {
                                component: (
                                  <AccessControl
                                    requires={[
                                      FeatureLevelPermissions.WRITE_DOCUMENTS_UPLOAD
                                    ]}>
                                    <MenuItem
                                      data-testid='move-document-menu-item'
                                      onClick={() =>
                                        setSelectedDocumentAction({
                                          action: 'moveDocument',
                                          document: {
                                            ...document,
                                            documentName
                                          }
                                        })
                                      }>
                                      <ListItemIcon>
                                        <DriveFileMoveIcon fontSize='small' />
                                      </ListItemIcon>
                                      Move Document
                                    </MenuItem>
                                  </AccessControl>
                                ),
                                isHidden: groupId === -2
                              },
                              {
                                component: (
                                  <AccessControl
                                    requires={[
                                      FeatureLevelPermissions.WRITE_DOCUMENTS_UPLOAD
                                    ]}>
                                    <MenuItem
                                      data-testid='revert-to-draft-menu-item'
                                      onClick={() =>
                                        setSelectedDocumentAction({
                                          action: 'revertDocumentToDraft',
                                          document: {
                                            ...document,
                                            documentName
                                          }
                                        })
                                      }>
                                      <ListItemIcon>
                                        <UndoIcon fontSize='small' />
                                      </ListItemIcon>
                                      Revert to Draft
                                    </MenuItem>
                                  </AccessControl>
                                ),
                                isHidden: groupId === -2 || isTPA
                              },
                              {
                                component: (
                                  <AccessControl
                                    requires={[
                                      FeatureLevelPermissions.WRITE_DOCUMENTS_UPLOAD
                                    ]}>
                                    <MenuItem
                                      data-testid='publish-document-history-menu-item'
                                      onClick={() =>
                                        setSelectedDocumentAction({
                                          action: 'publishDocument',
                                          document: {
                                            ...document,
                                            documentName
                                          }
                                        })
                                      }>
                                      <ListItemIcon>
                                        <DriveFileMoveIcon fontSize='small' />
                                      </ListItemIcon>
                                      Publish Document
                                    </MenuItem>
                                  </AccessControl>
                                ),
                                isHidden: groupId !== -2
                              }
                            ]}
                            open={open}
                          />
                        ) : (
                          <AccessControl
                            requires={[
                              FeatureLevelPermissions.WRITE_DOCUMENTS_UPLOAD
                            ]}>
                            <Tooltip title='Upload'>
                              <Button
                                className={classes.buttonIcon}
                                data-testid='document-action-upload-button'
                                onClick={() => {
                                  openDialog({
                                    customContent: (
                                      <UploadPlanDialog
                                        categoryId={categoryId}
                                        documentKey={document.documentKey}
                                        documentName={documentName}
                                        groupingId={groupId}
                                        planId={sponsorPlanId}
                                        setOpen={setOpen}
                                      />
                                    )
                                  });
                                }}
                                size='large'
                                startIcon={
                                  <FileUploadOutlinedIcon fontSize='small' />
                                }
                              />
                            </Tooltip>
                          </AccessControl>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {document.fileName
                      ? effectiveDateValue
                      : EMPTY_FIELD_PLACEHOLDER}
                  </TableCell>
                  <TableCell>
                    {document.fileName ? (
                      <Tooltip title={`Click to download ${document.fileName}`}>
                        <Link
                          color='primary'
                          onClick={() =>
                            document.uploadHistoryId
                              ? downloadDocument(document.uploadHistoryId)
                              : null
                          }
                          sx={{ cursor: 'pointer' }}>
                          {document.fileName}
                        </Link>
                      </Tooltip>
                    ) : (
                      EMPTY_FIELD_PLACEHOLDER
                    )}
                  </TableCell>
                  <TableCell>
                    {document.createdAt
                      ? formatters.formatFromIsoDateCustom(
                          document.createdAt,
                          'MM/DD/YYYY hh:mm:ss A'
                        )
                      : EMPTY_FIELD_PLACEHOLDER}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default PlanDocumentsTable;
