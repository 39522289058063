import AccessControl from '@/components/access-control/AccessControl.component';
import SplitButton from '@/components/split-button';
import type { PlanV2Dto } from '@/models';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import {
  CancelContribution,
  HideContribution,
  ReleaseContribution
} from '@/routes/plans/plan-contribution-submission';
import ContributionService from '@/services/Contribution.service';
import { PlanService } from '@/services/Plan.service';
import Grid from '@mui/material/Unstable_Grid2';
import { useQuery } from '@tanstack/react-query';

import React, { FC, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type ActionsProps = {
  ucid: string;
  planId: number;
};

export const Actions: FC<ActionsProps> = props => {
  const navigate = useNavigate();
  const location = useLocation();

  const plan = useQuery<PlanV2Dto>(
    [PlanService.getPlanById.name, props.planId],
    () => PlanService.getPlanById(props.planId),
    { enabled: !!props.planId }
  );

  const contributionActions = useQuery(
    [
      ContributionService.getContributionActions.name,
      plan.data?.data?.id,
      plan.data?.data?.relationships?.sponsor?.data?.id,
      props.ucid
    ],
    () =>
      ContributionService.getContributionActions(
        { ucid: props.ucid },
        {
          planId: plan.data?.data?.id,
          sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id
        }
      ),
    {
      enabled: !!plan.data,
      keepPreviousData: true,
      refetchInterval: 3000,
      refetchOnMount: 'always'
    }
  );

  const contribution = useQuery(
    [
      ContributionService.getContributionDetails.name,
      0,
      plan.data?.data?.id,
      plan.data?.data?.relationships?.sponsor?.data?.id,
      props.ucid
    ],
    () =>
      ContributionService.getContributionDetails({
        pageSize: 0,
        planId: plan.data?.data?.id,
        sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id,
        ucid: props.ucid
      }),
    {
      enabled: !!plan.data,
      refetchOnMount: 'always'
    }
  );

  const isCancelAvailable = useMemo(
    () =>
      contributionActions.data?.statusAndActionsData?.actions?.includes(
        'CANCEL'
      ),
    [contributionActions.data]
  );

  const isReleaseAvailable = useMemo(
    () =>
      contributionActions.data?.pngStatuses?.[
        contributionActions.data?.pngStatuses?.length - 1
      ]?.status === 'HELD',
    [contributionActions.data]
  );

  const isHideAvailable = useMemo(
    () =>
      ['COMPLETE', 'COMPLETED'].includes(
        contributionActions.data?.status?.toUpperCase()
      ),
    [contributionActions.data]
  );

  const isCorrectionButtonVisible = useMemo(
    () =>
      plan.data?.data?.attributes?.recordkeeper ===
        'Vestwell Sub-Accounting Platform' &&
      contribution.data?.key?.flowSubtype !== 'correction',
    [
      plan.data?.data?.attributes?.recordkeeper,
      contribution.data?.key?.flowSubtype
    ]
  );

  const handleClick = useCallback(() => {
    sessionStorage.removeItem('corrections');
    sessionStorage.removeItem('initialRows');
    sessionStorage.removeItem('rows');
    sessionStorage.removeItem('info');

    navigate(`/plans/${props.planId}/contributions/${props.ucid}/corrections`, {
      state: location?.state
    });
  }, [history, props.planId, props.ucid]);

  const handleMenuItemClick = useCallback(
    (index, value) => {
      sessionStorage.removeItem('corrections');
      sessionStorage.removeItem('initialRows');
      sessionStorage.removeItem('rows');
      sessionStorage.removeItem('info');

      navigate(value, { state: { isFullReversal: true } });
    },
    [history]
  );

  return (
    <Grid display='flex' gap={2} my={2}>
      {isCancelAvailable && (
        <AccessControl requires={[FeatureLevelPermissions.WRITE_CONTRIBUTION]}>
          <CancelContribution
            flowSubtype={contribution.data?.key?.flowSubtype}
            sponsorId={plan.data?.data?.relationships?.sponsor?.data?.id}
            sponsorPlanId={plan.data?.data?.id}
            ucid={props.ucid}
          />
        </AccessControl>
      )}
      {isReleaseAvailable && (
        <AccessControl
          requiresOneOf={[
            FeatureLevelPermissions.WRITE_CONTRIBUTION,
            FeatureLevelPermissions.WRITE_CONTRIBUTION_ACTION_CENTER
          ]}>
          <ReleaseContribution
            flowSubtype={contribution.data?.key?.flowSubtype}
            sponsorId={plan.data?.data?.relationships?.sponsor?.data?.id}
            sponsorPlanId={plan.data?.data?.id}
            ucid={props.ucid}
          />
        </AccessControl>
      )}
      {isHideAvailable && (
        <AccessControl
          requires={[FeatureLevelPermissions.WRITE_CONTRIBUTION_HIDE]}>
          <HideContribution
            flowSubtype={contribution.data?.key?.flowSubtype}
            sponsorPlanId={plan.data?.data?.id}
            ucid={props.ucid}
          />
        </AccessControl>
      )}
      {isCorrectionButtonVisible && (
        <AccessControl
          requires={[FeatureLevelPermissions.WRITE_CONTRIBUTION_CORRECTIONS]}>
          <div>
            <SplitButton
              dataTestId='contributionsOverview-makeCorrections-button'
              disabled={
                !['COMPLETE', 'COMPLETED'].includes(
                  contribution.data?.status?.toUpperCase()
                )
              }
              label='Make Corrections'
              onClick={handleClick}
              onMenuItemClick={handleMenuItemClick}
              options={[
                {
                  dataTestId:
                    'contributionsOverview-reverseContribution-button',
                  label: 'Reverse Contribution',
                  value: `/plans/${props.planId}/contributions/${props.ucid}/corrections`
                }
              ]}
              variant='contained'
            />
          </div>
        </AccessControl>
      )}
    </Grid>
  );
};
