import AccessControl from '@/components/access-control/AccessControl.component';
import TooltipButton from '@/components/tool-tip-button';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import {
  HceEmployee,
  PlanTestResultDto,
  TestExecutionResultsDto,
  WorkflowProcessEventResponse
} from '@/models/YearEndTestingDTO.model';
import {
  TestExecutionDropdown,
  TestExecutionDropdownProps
} from '@/routes/plans/plan-detail/PlanAnnualTestingTab/TestExecutionDropdown.component';
import { PlanService } from '@/services/Plan.service';
import { annualTestExecutionValidationSchema } from '@/utils/validations/AnnualTestExecutionValidationSchema.schema';
import {
  Check,
  KeyboardArrowDown,
  KeyboardArrowRight
} from '@mui/icons-material';
import {
  Alert,
  Collapse,
  Unstable_Grid2 as Grid,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import React, { FC, useCallback, useMemo } from 'react';
import { useToggle } from 'react-use';

interface TestExecutionRowProps {
  completedEvents: (string | undefined)[];
  event: WorkflowProcessEventResponse | undefined;
  onCompleteStep: (action: string) => void;
  planId: number;
  year?: number;
  data: PlanTestResultDto['testExecution'];
}

export const initialValues = {
  result402g: {
    additionalData: {
      hce: []
    },
    result: 'N/A',
    testId: 4
  },
  result415: {
    additionalData: {
      hce: []
    },
    result: 'N/A',
    testId: 3
  },
  resultACP: {
    additionalData: {
      hce: [],
      qnec: 0
    },
    result: 'N/A',
    testId: 2
  },
  resultADP: {
    additionalData: {
      hce: [],
      qnec: 0
    },
    result: 'N/A',
    testId: 1
  },
  resultTopHeavyCurrentYear: {
    additionalData: {
      minC: 0
    },
    result: 'N/A',
    testId: 5
  }
};

export const mapHce = (
  values: TestExecutionResultsDto
): TestExecutionResultsDto => {
  const newValues = {} as TestExecutionResultsDto;
  Object.entries(values).forEach(([key, value]) => {
    value.additionalData.hce
      ? (newValues[key] = {
          ...value,
          additionalData: {
            ...value.additionalData,
            hce: JSON.stringify(
              value.additionalData.hce.map((e: HceEmployee) => ({
                earnings: e.earnings,
                fundingSource: e.fundingSource,
                id: e.id,
                lostGain: e.lostGain,
                name: e.name,
                value: e.value
              }))
            )
          }
        })
      : (newValues[key] = value);
  });
  return newValues;
};

export const TestExecutionRow: FC<TestExecutionRowProps> = props => {
  const { showSnackbar } = useSnackbar();

  const [open, toggleOpen] = useToggle(false);

  const postYETTestResultsMutation = useMutation(
    (data: TestExecutionResultsDto) =>
      PlanService.postYETTestResults(props.planId, data, props.year),
    {
      onError: () => {
        showSnackbar({
          message: 'Something went wrong!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Test Results have been saved!',
          severity: 'success'
        });
        props.onCompleteStep('completeTestExecution');
      }
    }
  );

  const postYETTestingResultsToSponsorsMutation = useMutation(
    () => PlanService.sendYETTestingResultsToSponsors(props.planId),
    {
      onError: () => {
        showSnackbar({
          message: 'Something went wrong!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Tests results have been emailed to the plan sponsor!',
          severity: 'success'
        });
      }
    }
  );

  const initValues = useMemo(() => {
    return {
      ...initialValues,
      ...props.data
    };
  }, [props.data]);

  const onSubmit = useCallback(
    (values: TestExecutionResultsDto) => {
      postYETTestResultsMutation.mutate(mapHce(values));
    },
    [postYETTestResultsMutation]
  );

  const sendEmail = useCallback(() => {
    postYETTestingResultsToSponsorsMutation.mutate();
  }, [postYETTestingResultsToSponsorsMutation]);

  const rows = useMemo<TestExecutionDropdownProps['row'][]>(
    () => [
      {
        hasHceJson: true,
        hasQnecTotal: true,
        label: 'resultADP',
        name: 'ADP'
      },
      {
        hasHceJson: true,
        hasQnecTotal: true,
        label: 'resultACP',
        name: 'ACP'
      },
      {
        hasHceJson: false,
        hasQnecTotal: true,
        label: 'resultTopHeavyCurrentYear',
        name: `Top Heavy (${props.year})`
      },
      {
        hasHceJson: true,
        hasQnecTotal: false,
        label: 'result402g',
        name: '402g Limits'
      },
      {
        hasHceJson: true,
        hasQnecTotal: false,
        label: 'result415',
        name: '415 Limits'
      }
    ],
    [props.year]
  );

  return (
    <>
      <TableRow data-testid='test-execution-row'>
        <TableCell align='center' padding='none'>
          <IconButton
            aria-label='expand row'
            data-testid='test-execution-expand-row-button'
            onClick={toggleOpen}
            size='small'>
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        </TableCell>
        <TableCell align='center' padding='none'>
          {!!props.event && (
            <Check color='primary' data-testid='test-execution-checkmark' />
          )}
        </TableCell>
        <TableCell>Test Execution</TableCell>
        <TableCell data-testid='test-execution-initiatedBy'>
          {props.event?.initiatedBy?.id}
        </TableCell>
        <TableCell>{props.event?.createdAt}</TableCell>
        <TableCell padding='none'>
          <AccessControl
            requires={[FeatureLevelPermissions.WRITE_ANNUAL_TESTING_ACTIONS]}>
            <TooltipButton
              disabled={
                postYETTestingResultsToSponsorsMutation.isLoading ||
                !props.completedEvents?.includes('yearEndContributionCompleted')
              }
              handleOnClick={sendEmail}
              testId='send-email-btn'
              tooltipMessage={
                props.completedEvents?.includes('yearEndContributionCompleted')
                  ? ''
                  : 'Please complete the previous step'
              }>
              SEND TEST RESULTS
            </TooltipButton>
          </AccessControl>
        </TableCell>
      </TableRow>
      <TableRow data-testid='test-execution-expandable-row'>
        <TableCell
          colSpan={12}
          padding='none'
          sx={{
            backgroundColor: theme => theme.palette.grey[50]
          }}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Formik
              initialValues={initValues}
              onSubmit={onSubmit}
              validationSchema={annualTestExecutionValidationSchema}>
              {({ handleSubmit, errors }) => (
                <Form data-testid='test-execution-form'>
                  <Grid
                    container
                    direction='column'
                    disableEqualOverflow={true}
                    padding={2}
                    spacing={2}>
                    <Grid lg={12}>
                      <Paper
                        data-testid='test-execution-results-card'
                        variant='outlined'>
                        <TableContainer>
                          <Table>
                            <TableBody>
                              {rows.map(row => (
                                <TestExecutionDropdown
                                  data={props.data}
                                  key={row.name}
                                  row={row}
                                />
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Grid>
                    <Grid>
                      <Alert severity='info'>
                        Please ensure there is an associated doc for each test
                        otherwise the results will not be saved. Annual testing
                        docs can be reviewed{' '}
                        <Link
                          data-testid='test-execution-redirection-link'
                          href={`/plans/${props.planId}/documents#annual-testing`}>
                          here
                        </Link>
                        .
                      </Alert>
                    </Grid>
                    <Grid>
                      <Alert severity='warning'>
                        Saved values will not be visible after leaving this
                        page.
                      </Alert>
                    </Grid>
                    <Grid alignItems='center' container>
                      <Grid>
                        <AccessControl
                          requires={[
                            FeatureLevelPermissions.WRITE_ANNUAL_TESTING_ACTIONS
                          ]}>
                          <TooltipButton
                            disabled={
                              postYETTestResultsMutation.isLoading ||
                              !isEmpty(errors) ||
                              !props.completedEvents?.includes(
                                'yearEndContributionCompleted'
                              )
                            }
                            handleOnClick={handleSubmit}
                            testId='save-test-execution-btn'
                            tooltipMessage={
                              props.completedEvents?.includes(
                                'yearEndContributionCompleted'
                              )
                                ? ''
                                : 'Please complete the previous step'
                            }
                            variant='contained'>
                            SAVE
                          </TooltipButton>
                        </AccessControl>
                      </Grid>
                      {!isEmpty(errors) && (
                        <Grid>
                          <Typography color='error' variant='body2'>
                            Please fix the highlighted errors
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
